<template>
	<div v-if="this.hours != '0' || this.minutes != '0'" class="tag-btn-time d-flex align-center justify-center mr-2 my-sm-0 my-3">
		<img :src="$store.state.icons.icons['Timer']" alt />
		<span class="d-sm-block d-none">
			Quote Expires in <br />
			{{ hours }}h {{ minutes }}m
		</span>
		<span class="d-sm-none d-block"> Quote Expires in {{ hours }}h {{ minutes }}m </span>
	</div>
</template>

<script>
import moment from 'moment'
export default {
	props: {
		value: String,
	},

	data() {
		return {
			hours: 0,
			interval: null,
			days: 0,
			minutes: 0,
			seconds: 0,
			intervals: {
				second: 1000,
				minute: 1000 * 60,
				hour: 1000 * 60 * 60,
				day: 1000 * 60 * 60 * 24,
			},
		}
	},

	watch: {
		// eslint-disable-next-line no-unused-vars
		value(newVal, oldVal) {
			this.updateDiffs()
		},
	},

	async created() {
		// let hours = await this.getHours();

		// setInterval(() => {
		//     if (hours > 0) {
		//         this.getHours();
		//     }
		// }, 60000);
		this.interval = setInterval(() => {
			this.updateDiffs()
		}, 1000)

		this.updateDiffs()
	},

	destroyed() {
		clearInterval(this.interval)
	},

	computed: {
		hoursFun() {
			return this.hours
		},
	},

	methods: {
		async updateDiffs() {
			var myDate = moment(this.value)
			var then = moment(myDate).add(4, 'hours').format('YYYY-MM-DDTHH:mm:ss')
			var now = moment.utc(Date.now()).format('YYYY-MM-DDTHH:mm:ss')
			then = moment(then, 'YYYY-MM-DDTHH:mm:ss')
			now = moment(now, 'YYYY-MM-DDTHH:mm:ss')
			// var hours = then.diff(now, "minutes");

			// if (hours < -1) {
			//     this.hours = 0;
			// } else {
			//     hours += 1;
			//     this.hours = hours;
			// }

			//lets figure out our diffs
			let diff = Math.abs(now - then)
			// this.days = Math.floor(diff / this.intervals.day);
			// diff -= this.days * this.intervals.day;
			this.hours = Math.floor(diff / this.intervals.hour)
			diff -= this.hours * this.intervals.hour
			this.minutes = Math.floor(diff / this.intervals.minute)
			// diff -= this.minutes * this.intervals.minute;
			// this.seconds = Math.floor(diff / this.intervals.second);
		},
	},
}
</script>

<style lang="scss"></style>
